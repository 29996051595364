import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useAppForm, useAppLoading, useAddressError } from "./";
import { Spinner } from "./spinner";
import CompText from "./compText";
import Graphics from "./graphics";
import PartnerTags from "./partnerTags";
import Logos from "./logos";
import PortalModal from "@components/UI/portalModal";

import cx from "classnames";

import Cheers from "@images/cheers.png";
import Tick from "@svg/tick.svg";
import Cross from "@svg/cross.svg";

export const EntryForm = ({ error }) => {
  const { handleChange, handleSub, handleSubmit } = useAppForm();
  const [checked, setChecked] = useState(true);
  const [open, setOpen] = useState(false);
  const isLoading = useAppLoading();

  const handleClose = () => setOpen(false);

  const handleChecked = () => {
    setChecked(!checked);
  };

  // set Sub state to true on first load
  useEffect(() => {
    return handleSub(true);
  }, []);

  useEffect(() => {
    if (error) {
      setOpen(true);
    }
  }, [error]);

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 10 }}
        className="h-full overflow-hidden w-full   "
      >
        <div className="relative">
          <div className="hidden md:block">
            <PartnerTags />
          </div>
          <Graphics formGraphics={true} />

          <CompText />

          <div className="relative pb-16 md:pb-[271px] z-20">
            <div className="">
              <form className="" onSubmit={handleSubmit} autocomplete="off">
                <div>
                  {/* Form */}
                  <div className="flex flex-col  md:grid grid-cols-2 gap-y-4 gap-x-6 mt-6 max-w-[285px] md:max-w-[800px] mx-auto">
                    <div class="col-span-2 relative z-0  w-full group">
                      <input
                        name="uniqueCode"
                        type="text"
                        required
                        placeholder=" "
                        onChange={handleChange}
                        className="codeInput peer"
                      />
                      <label
                        for="uniqueCode"
                        className="codeLabel pointer-events-none "
                      >
                        UNIQUE CODE
                      </label>
                    </div>

                    <div class="relative z-0  w-full group">
                      <input
                        name="firstName"
                        type="text"
                        required
                        placeholder=" "
                        onChange={handleChange}
                        className="regularInput peer"
                      />
                      <label
                        for="firstName"
                        className="regularLabel pointer-events-none "
                      >
                        FIRST NAME
                      </label>
                    </div>

                    <div class="relative z-0  w-full group">
                      <input
                        name="lastName"
                        type="text"
                        required
                        placeholder=" "
                        onChange={handleChange}
                        className="regularInput peer"
                      />
                      <label
                        for="lastName"
                        className="regularLabel pointer-events-none "
                      >
                        LAST NAME
                      </label>
                    </div>

                    <div class="relative z-0  w-full group">
                      <input
                        name="email"
                        type="email"
                        required
                        placeholder=" "
                        onChange={handleChange}
                        className="regularInput peer"
                      />
                      <label
                        for="email"
                        className="regularLabel pointer-events-none "
                      >
                        EMAIL ADDRESS
                      </label>
                    </div>
                    <div class="relative z-0  w-full group">
                      <input
                        name="phone"
                        type="text"
                        inputMode="tel"
                        required
                        placeholder=" "
                        onChange={handleChange}
                        className="regularInput peer"
                        //  pattern="\d*"
                      />
                      <label
                        for="phone"
                        className="regularLabel pointer-events-none "
                      >
                        PHONE NUMBER
                      </label>
                    </div>

                    <div className="flex items-center col-span-2  ">
                      <input
                        type="checkbox"
                        name="subscribe"
                        id="subscribe"
                        checked={checked}
                        onChange={handleChecked}
                        onClick={() => handleSub(checked ? false : true)}
                      />
                      <label
                        for="subscribe"
                        className=" text-[10px] md:text-[14px] mx-auto font-bold letter-spacing-8 relative mt-4"
                      >
                        <div
                          className={cx(
                            " w-[33px] h-[33px] rounded-[5px] absolute -top-1 -left-5 transition-all duration-300 ease-in-out ",
                            {
                              "bg-blue-dark": checked,
                              "bg-white drop-shadow-2xl": !checked,
                            }
                          )}
                        >
                          {checked && (
                            <Tick className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2" />
                          )}
                        </div>

                        <span className="block ml-8">
                          I agree to hear more from the participating brands,
                          promotions and other offers.
                        </span>
                      </label>
                    </div>

                    <button
                      type="submit"
                      className=" bg-blue-dark  text-white w-fit mx-auto col-span-2 py-3 px-14 rounded-[10px] mt-6 text-[25px] hover:bg-blue-dark/90 font-azo-bold flex items-center"
                    >
                      SUBMIT
                      {isLoading && (
                        <div className="ml-4">
                          <Spinner />
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div>
          <Logos />
          <div className="bg-blue-dark pt-[100px] pb-[80px] flex justify-between px-10 md:px-20 relative z-30">
            <div>
              <h4 className=" font-grotesque text-white tracking-[0.2em] text-[15px]">
                DRINK RESPONSIBLY
              </h4>
            </div>
            <a href="https://cheers.org.nz/" target="_blank">
              <img src={Cheers} alt="" className="w-[82px] mx-auto" />
            </a>
          </div>
        </div>
      </motion.div>
      {/* modal starts */}
      <PortalModal isShowing={open}>
        <AnimatePresence>
          <div className="bg-yellow my-10 max-w-[1200px] max-h-[600px] m-auto self-center   relative ">
            <motion.div
              whileHover={{
                scale: 1.2,
              }}
              onClick={handleClose}
              className="absolute top-10 right-10 cursor-pointer  rounded-full h-16 w-16 "
            >
              <Cross className="w-[20px] md:w-[40px] absolute -top-8 right-2 md:top-1/2 md:left-1/2 md:transform md:-translate-y-1/2 md:-translate-x-1/2" />
            </motion.div>

            <div className="px-gutter md:px-40 py-28  lg:py-[181px]   text-center  ">
              <h1 className="font-azo text-[20px] md:text-[50px] text-blue-dark pb-5">
                oops
              </h1>
              {error === "code" ? (
                <div className=" font-book text-[32px]">
                  <p>
                    Looks like this code is incorrect or has already been used.
                  </p>
                  <p>Enter another code to continue.</p>
                </div>
              ) : error === "limit" ? (
                <div className=" font-book text-[32px]">
                  <p>Looks like you’ve exceeded the daily entry limit.</p>
                  <p>Try your luck again tomorrow.</p>
                </div>
              ) : (
                <div className=" font-book text-[32px]">
                  <p>Looks like you’ve exceeded the weekly entry limit.</p>
                  <p>Try your luck again tomorrow.</p>
                </div>
              )}
            </div>
          </div>
        </AnimatePresence>
      </PortalModal>
    </>
  );
};
