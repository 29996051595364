import React from "react"
import PropTypes from "prop-types"
import loadable from "@loadable/component"

const ImageText = loadable(() => import("./modules/imageText"))

const ModuleZone = ({ modules }) => {
  const component = modules.map(item => {
    switch (item._type) {
      case "imageText":
        return <ImageText {...item} />
      default:
        return null
    }
  })
  return <>{component}</>
}

ModuleZone.propTypes = {
  modules: PropTypes.array.isRequired,
}

export default ModuleZone
