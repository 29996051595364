import React, { createContext, useContext, useState } from "react";
import { AnimatePresence } from "framer-motion";

import { EntryForm } from "./entryForm";
import { AgeGate } from "./ageGate";
import { Draw } from "./draw";
import { Congrats } from "./congrats";

const initialState = {
  isAge: false,
  isSubmitted: false,
  instantWinner: false,
  formValues: {},
  addressError: false,
  error: false,
  prize: false,
};

//

const isClient = typeof window === "object";

export const AppContext = createContext({
  compState: initialState,
  setAppState: () => null,
});

const FormInner = () => {
  const { isSubmitted, isAge, error, instantWinner, prize } = useAppState();

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <div className="background-image">
          {!isAge ? (
            <AgeGate />
          ) : !isSubmitted ? (
            <EntryForm error={error} />
          ) : instantWinner ? (
            <Congrats prize={prize} />
          ) : (
            <Draw />
          )}
        </div>
      </AnimatePresence>
    </>
  );
};

const CompForm = () => {
  const [compState, setCompState] = useState(initialState);
  const [isLoading, setLoading] = useState(false);

  return (
    <AppContext.Provider
      value={{ compState, setCompState, isLoading, setLoading }}
    >
      <div>
        <FormInner />
      </div>
    </AppContext.Provider>
  );
};

function useAppState() {
  const { compState } = useContext(AppContext);
  return compState;
}

function useAppLoading() {
  const { isLoading } = useContext(AppContext);
  return isLoading;
}

function useAddressError() {
  const {
    compState: { addressError },
  } = useContext(AppContext);
  return addressError;
}

function useAgeGate() {
  const { setCompState } = useContext(AppContext);

  function setAge() {
    setCompState((prevState) => ({
      ...prevState,
      isAge: true,
    }));
  }

  return { setAge };
}

function useAppForm() {
  const { setCompState, compState, setLoading } = useContext(AppContext);

  // Form Change Function
  function handleChange(event) {
    event.persist();
    setCompState((prevState) => ({
      ...prevState,
      addressError: false,
      formValues: {
        ...prevState.formValues,
        [event.target.name]: event.target.value.toUpperCase(),
      },
    }));
  }

  function handleSub(check) {
    setCompState((prevState) => ({
      ...prevState,
      addressError: false,
      formValues: {
        ...prevState.formValues,
        subscribe: check,
      },
    }));
  }

  async function fetchCompResult(data) {
    const response = await fetch("/.netlify/functions/handle-comp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return response;
  }

  // Form Handle Submit
  async function handleSubmit(event) {
    event.preventDefault();

    // Set Loading
    setLoading(true);
    // Await response from endpoint
    const { codeInvalid, instantWinner, dailyLimit, weeklyLimit, prize } =
      await fetchCompResult({
        ...compState.formValues,
      });

    isClient && window.scrollTo(0, 0);
    setLoading(false);

    setCompState((prevState) => ({
      ...prevState,
      isSubmitted: !codeInvalid && !dailyLimit && !weeklyLimit,
      error: codeInvalid
        ? "code"
        : dailyLimit
        ? "limit"
        : weeklyLimit
        ? "weekly limit"
        : false,
      instantWinner: instantWinner,
      prize: prize,
    }));
  }

  // Provide a reset
  function handleReset() {
    isClient && window.scrollTo(0, 0);
    setCompState((prevState) => ({
      ...prevState,
      isSubmitted: false,
    }));
  }

  return { handleChange, handleSub, handleReset, handleSubmit };
}

export {
  CompForm,
  useAppState,
  useAppForm,
  useAppLoading,
  useAgeGate,
  useAddressError,
};
