import React from "react";

const CompText = () => {
  return (
    <div className="pt-[80px] pb-[40px] md:py-32 text-center text-blue-dark relative z-30 ">
      <h3 className=" font-book text-[30px] md:text-[70px] tracking-[0.2em] pb-3 ">
        INSTANT
      </h3>
      <div className="font-azo text-[50px] md:text-[175px] leading-[84%] ">
        <h3 className="">summer</h3>
        <h3 className="">shout</h3>
      </div>

      <div className="text-[30px] leading-[16px] md:leading-[36px] max-w-[285px] md:max-w-[750px] pt-5 mx-auto  space-y-5 md:space-y-7">
        <p className=" font-bold text-[15px] md:text-[30px]">
          Win instantly, 1 of 300 x $50 Uber Vouchers or 150 x $100 Koha cards.
        </p>
        <p className=" font-book text-[14px] md:text-[30px]">
          Buy any 3 participating beverages to get your entry card, enter your
          details and unique code from the card below to find out if you’re a
          winner.
        </p>
      </div>
    </div>
  );
};

export default CompText;
