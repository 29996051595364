import { useStaticQuery, graphql } from "gatsby";

import { QueryFragments } from "./queryFragments"; // eslint-disable-line

export const useTermsQuery = () => {
  const { sanityTerms } = useStaticQuery(graphql`
    query {
      sanityTerms {
        title
        text: _rawText(resolveReferences: { maxDepth: 10 })
      }
    }
  `);
  return sanityTerms || {};
};
