import React from "react";
import Graphics from "./graphics";
import PartnerTags from "./partnerTags";

// import Logo from "@images/logo.png";

export const Congrats = ({ prize }) => {
  return (
    <div className="px-gutter h-full min-h-screen flex flex-col font-book text-blue-dark text-center  justify-center overflow-hidden">
      <Graphics final={true} />
      <div className="md:hidden">
        <PartnerTags />
      </div>

      <h3 className="z-40 tracking-[0.2em] mb-5 md:mb-[29px] text-[20px] md:text-[40px]">
        CONGRATULATIONS!
      </h3>
      <div className="z-40 max-w-[1100px] mx-auto">
        <p className="font-bold text-[15px] md:text-[40px]">
          You've won a {prize}.
        </p>
        <p className="text-[10px] md:text-[25px] max-w-[470px] mx-auto mt-5 md:mt-[39px]">
          Your prize will be emailed within the next 7 working days.
        </p>
      </div>
    </div>
  );
};
