import React from "react";
import cx from "classnames";

import HalfCircle from "@images/halfcircle.png";
import BigCircle from "@images/bigcircle.png";
import SmallBlue from "@images/smallblue.png";
import BeersSmall from "@images/beerssmall.png";
import BeersMobile from "@images/beers-mobile.png";
import BlueCircleMobile from "@images/blue-circle-mobile.png";
import HalfcircleMobile from "@images/halfcircle-mobile.png";
import BeersFinal from "@images/beersfinal.png";

import Beers from "@images/beers.png";

const Graphics = ({ final, formGraphics }) => {
  return (
    <>
      <div className="hidden md:block w-[50vw] absolute top-0 left-0 ">
        <img src={HalfCircle} alt="" className="w-full   " />
      </div>
      <div className="md:hidden w-[100vw] absolute top-0 left-0 ">
        <img src={HalfcircleMobile} alt="" className="w-full   " />
      </div>
      <div
        className={cx(
          " w-[80vw] absolute -bottom-36 left-1/2 -translate-x-1/2 z-10 ",
          {
            hidden: final,
            "hidden md:block": !final,
          }
        )}
      >
        <img src={Beers} alt="" className="w-full   " />
      </div>
      {/* beers mobile */}
      <div
        className={cx(
          " md:hidden w-[120vw] absolute  left-1/2 -translate-x-1/2 z-10 ",
          {
            hidden: final,
            "-bottom-64": !formGraphics,
            "-bottom-[400px]": formGraphics,
          }
        )}
      >
        <img src={BeersMobile} alt="" className="w-full   " />
      </div>
      <div
        className={cx("w-full absolute -bottom-32 left-0 ", {
          hidden: final,
          "hidden md:block ": !final,
        })}
      >
        <img src={BigCircle} alt="" className="w-full   " />
      </div>
      {/* blue circle mobile */}
      <div
        className={cx(" w-full absolute left-0 ", {
          hidden: final,
          "-bottom-[420px] md:hidden ": !formGraphics,
          "-bottom-[800px] md:hidden ": formGraphics,
        })}
      >
        <img src={BlueCircleMobile} alt="" className="w-full   " />
      </div>
      <div
        className={cx("w-full absolute bottom-0 right-0", {
          hidden: !final,
          "hidden md:block": final,
        })}
      >
        <img src={SmallBlue} alt="" className="w-full   " />
      </div>
      <div
        className={cx(" w-[40vw] absolute bottom-0 right-0  z-10 ", {
          hidden: !final,
          "hidden md:block": final,
        })}
      >
        <img src={BeersSmall} alt="" className="w-full   " />
      </div>
      <div
        className={cx("md:hidden w-[100vw] absolute -bottom-0 right-0  z-10", {
          hidden: !final,
        })}
      >
        <img src={BeersFinal} alt="" className="w-full   " />
      </div>
    </>
  );
};

export default Graphics;
