import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";

// Hooks Querys & Context
import { QueryFragments } from "@querys/queryFragments"; // eslint-disable-line

import ModuleZone from "~moduleZone";
import Layout from "@components/layout";
import { PageMeta } from "@components/pageMeta";

import { CompForm } from "../components/compForm/";

const IndexPage = () => {
  // const {  seo } = page || {}

  return (
    <Layout>
      {/* <PageMeta {...seo} /> */}

      <CompForm />
    </Layout>
  );
};

export default IndexPage;

// export const pageQuery = graphql`
//   query homePageQuery {
//     page: sanityHomePage {
//       seo {
//         ...SEO
//       }
//       pageModules: modules {
//         ...PageModules
//       }
//     }
//   }
// `;
