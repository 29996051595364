import React from "react";
import Graphics from "./graphics";
import PartnerTags from "./partnerTags";

// import Logo from "@images/logo.png";

export const Draw = () => {
  return (
    <div className="h-full min-h-screen flex flex-col font-book text-blue-dark text-center text-[20px] md:text-[40px] justify-center px-10 md:px-0">
      <Graphics final={true} />
      <div className="md:hidden">
        <PartnerTags />
      </div>
      <h3 className="z-40 tracking-[0.2em] mb-[29px]">SORRY!</h3>
      <div className="z-40 max-w-[1100px] mx-auto">
        <p>You are not an instant winner this time!</p>
        <p>
          You’re in this week’s draw to win one of three $500 Uber voucher to
          get you home safe all summer. Good luck!
        </p>
        <p className="mt-4 md:mt-0">
          We’ll be in touch on Monday if you are a weekly winner.
        </p>
      </div>
    </div>
  );
};
