import React from "react";
import { Link } from "gatsby";

import Partners from "@images/partners.png";
import KohaImage from "@images/logo-koha.png";
import UberImage from "@images/logo-uber.png";

const PartnerTags = () => {
  return (
    <div className="absolute bottom-[27px] left-[30px] md:left-[100px]   z-20  ">
      <h4 className="font-book text-blue-dark text-[15px] md:text-[25px] mb-4 md:mb-9 text-center pl-5 md:pl-12">
        Prize inclusions
      </h4>
      {/* <div className="w-[204px] md:w-[312px] h-full  ">
        <img src={Partners} alt="" className="" />
      </div> */}
      <div className="flex items-center w-[204px]  md:w-[312px] h-full  divide-x-2 divide-black space-x-10">
        <div>
          <img src={UberImage} alt="" className="" />
        </div>
        <Link
          to="https://kohacard.co.nz/"
          className="pl-4 md:pl-8"
          target="_blank"
        >
          <img src={KohaImage} alt="" className="" />
        </Link>
      </div>
    </div>
  );
};

export default PartnerTags;
