import React from "react";
import PortableText from "react-portable-text";
import cx from "classnames";

import SwitchLink from "@components/links/switchLink";

const SimplePortableText = ({
  text,
  centerText = false,
  className,
  ...props
}) => {
  return text ? (
    <PortableText
      content={text}
      serializers={{
        normal: (props) => <p {...props} className="font-display pb-2" />,
        h1: (props) => <h1 {...props} className="font-display" />,
        h2: (props) => <h2 {...props} className="font-display" />,
        h3: (props) => <h3 {...props} className="font-display" />,
        h4: (props) => <h4 {...props} className="font-display" />,
        h5: (props) => <h5 {...props} className="font-display" />,
        h6: (props) => <h6 {...props} className="font-display" />,
        ul: (props) => <ul {...props} className="list-disc  pl-[2rem] ml-0" />,
        ol: (props) => <ol {...props} className="pl-[2rem] ml-0 pb-2" />,
        hardBreak: (props) => <span className=" list-none h-[10px] block" />,
        link: (props) => (
          <a {...props} className="font-display" target="_blank" />
        ),
        pageLink: (props) => <SwitchLink {...props} />,
        pageAnchorLink: (props) => <SwitchLink {...props} />,
        anchorLink: (props) => <SwitchLink {...props} />,
      }}
      className={cx(className, {
        "portable-text": true,
      })}
      {...props}
    />
  ) : null;
};

export default SimplePortableText;
