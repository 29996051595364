import React, { useState, useRef } from "react";
import { useTermsQuery } from "@querys/useTermsQuery";
import PortalModal from "@components/UI/portalModal";
import { motion, AnimatePresence } from "framer-motion";
import SimplePortableText from "@components/sanity/simplePortableText";
import { useAgeGate } from "../compForm";
import Graphics from "./graphics";
import PartnerTags from "./partnerTags";
import Logos from "./logos";
import cx from "classnames";

import Cross from "@svg/cross.svg";
import Tick from "@svg/tick.svg";

export const AgeGate = () => {
  const { setAge } = useAgeGate();
  const [day, setDay] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(0);
  const [checked, setChecked] = useState(false);
  const { title, text } = useTermsQuery();
  const [open, setOpen] = useState(false);
  const [dateMessage, setDateMessage] = useState("");
  const [termsMessage, setTermsMessage] = useState("");

  const dayFocus = useRef();
  const monthFocus = useRef();
  const yearFocus = useRef();
  const termsFocus = useRef();
  const enterFocus = useRef();

  const handleClose = () => setOpen(false);
  const handleChange = () => {
    setChecked(!checked);
  };

  const underAgeValidate = (birthday) => {
    let birthDate = new Date(birthday);
    let ageDifMs = Date.now() - birthDate.getTime();
    let ageDate = new Date(ageDifMs);
    let age = Math.abs(ageDate.getUTCFullYear() - 1970);

    if (age > 17) {
      return true;
    } else {
      return false;
    }
  };

  const handleEnter = () => {
    const formattedDate = `${year}/${month}/${day}`;
    const isValid = underAgeValidate(formattedDate);

    if (checked && isValid && year.length === 4) {
      setAge(true);
    } else if (year.length !== 4) {
      setDateMessage("Please enter your date of birth");
    } else if (!checked && !isValid) {
      setTermsMessage("Please accept the terms and conditions");
      setDateMessage("You must be over 18 to enter");
    } else if (!checked && isValid) {
      setTermsMessage("Please accept the terms and conditions");
      setDateMessage("");
    } else if (!isValid && checked) {
      setDateMessage("You must be over 18 to enter");
      setTermsMessage("");
    }
  };

  return (
    <>
      <motion.div className="min-h-screen overflow-hidden text-blue-dark  relative h-full flex justify-center items-center pb-52  md:pb-44">
        <PartnerTags />
        <Graphics />
        <div className="z-40 ">
          <div className="text-center py-10 md:py-16">
            <h3 className=" font-book text-[30px] md:text-[35px] tracking-[0.2em] pb-3 ">
              INSTANT
            </h3>
            <div className="font-azo text-[50px] md:text-[100px] leading-[84%] ">
              <h3 className="">summer</h3>
              <h3 className="">shout</h3>
            </div>
          </div>
          <h1 className="font-azo text-[28px] md:text-[50px] leading-[1.38em]  text-center max-w-[300px] md:max-w-[500px] mx-auto  ">
            please verify your age
          </h1>
          <div className="mx-auto w-full max-w-[370px] md:max-w-[500px] grid grid-cols-10 gap-x-2 md:gap-x-7 justify-center mt-10 md:mt-5 font-black">
            <div className="col-span-3">
              <input
                className="dateInput"
                ref={dayFocus}
                pattern="[0-9]*"
                type="number"
                maxlength="2"
                id="dateInput "
                autocomplete="off"
                placeholder="DD"
                required
                onChange={(e) => {
                  setDay(e.target.value);
                  if (e.target.value.length >= 2) monthFocus.current.focus();
                }}
              />
            </div>
            <div className="col-span-3">
              <input
                className="dateInput "
                ref={monthFocus}
                pattern="[0-9]*"
                type="number"
                maxlength="2"
                max="12"
                id="monthInput"
                autocomplete="off"
                placeholder="MM"
                required
                onChange={(e) => {
                  setMonth(e.target.value);
                  if (e.target.value.length >= 2) yearFocus.current.focus();
                }}
              />
            </div>
            <div className="col-span-4">
              <input
                className="dateInput "
                ref={yearFocus}
                pattern="[0-9]*"
                type="number"
                maxlength="4"
                id="yearInput"
                autocomplete="off"
                placeholder="YYYY"
                required
                onChange={(e) => {
                  setYear(e.target.value);
                  if (e.target.value.length >= 4) termsFocus.current.focus();
                }}
              ></input>
            </div>
          </div>

          <p className="text-center text-orange-dark mt-2 absolute text-[12px] md:text-[18px] right-1/2 translate-x-1/2">
            {" "}
            {dateMessage}
          </p>
          <div className="font-black  mt-[30px] md:mt-[54px] w-full flex ">
            <div className="mx-auto flex ">
              <label className="relative">
                <div
                  className={cx(
                    " w-[33px] h-[33px] rounded-[5px] absolute -top-1 -left-2 transition-all duration-300 ease-in-out ",
                    {
                      "bg-blue-dark": checked,
                      "bg-white drop-shadow-2xl": !checked,
                      // "border-2 border-blue-dark":
                      //   document?.activeElement === termsFocus?.current,
                    }
                  )}
                >
                  {checked && (
                    <Tick className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2" />
                  )}
                </div>
                <input
                  ref={termsFocus}
                  type="checkbox"
                  checked={checked}
                  onKeyPress={(e) => {
                    e.key === "Enter" && handleChange();
                    enterFocus.current.focus();
                  }}
                  onChange={(e) => {
                    handleChange();
                    if (e.target.value) enterFocus.current.focus();
                  }}
                />
                <span className="checkmark"></span>
              </label>

              <div className=" my-auto  text-[12px] md:text-[18px] pl-5">
                I AGREE TO THE{" "}
                <span
                  className="underline underline-offset-2 cursor-pointer"
                  onClick={() => setOpen(true)}
                >
                  TERMS & CONDITIONS*{" "}
                </span>
              </div>
            </div>
          </div>
          <p className="text-center  mt-2 absolute translate-x-1/3 text-[12px] md:text-[18px]">
            {" "}
            {termsMessage}
          </p>
          <div className="w-full flex mt-5 md:mt-10">
            <button
              ref={enterFocus}
              className="bg-blue-dark  text-white w-fit mx-auto col-span-2 py-5 px-20 rounded-[10px] mt-6 text-[25px] md:text-[35px] hover:bg-blue-dark/90 font-azo-bold"
              onClick={handleEnter}
            >
              ENTER
            </button>
          </div>
        </div>
      </motion.div>
      <Logos />
      {/* modal starts */}
      <PortalModal isShowing={open}>
        <AnimatePresence>
          <div className="bg-yellow my-10 w-full h-full self-center   relative  overflow-y-scroll">
            <motion.div
              whileHover={{
                scale: 1.2,
              }}
              onClick={handleClose}
              className="absolute top-10 right-10 cursor-pointer  rounded-full h-16 w-16 "
            >
              <Cross className="w-[20px] md:w-[40px] absolute -top-8 right-2 md:top-1/2 md:left-1/2 md:transform md:-translate-y-1/2 md:-translate-x-1/2" />
            </motion.div>

            <div className="px-4 py-28  lg:py-[181px]  lg:px-[161px]  ">
              <h1 className="font-bold text-[20px] md:text-[40px] text-blue-dark text-center pb-16">
                {title}
              </h1>

              <SimplePortableText text={text} className="text-blue-dark" />
            </div>
          </div>
        </AnimatePresence>
      </PortalModal>
    </>
  );
};
