import React from "react";

import Cuervo from "@images/cuervo.png";
import Bacardi from "@images/bacardi.png";
import BombaySapphire from "@images/bombay-sapphire.png";
import DanielLeBrun from "@images/daniel-le-brun.png";
import TheNed from "@images/the-ned.png";
import WitherHills from "@images/wither-hills.png";
import Emersons from "@images/emersons.png";
import Panhead from "@images/panhead.png";
import Steinie from "@images/steinie.png";

const Logos = () => {
  return (
    <div className="w-full grid grid-cols-3 lg:grid-cols-9 pt-[56px] pb-[55px] md:pt-[34px] md:pb-[38px] px-12 bg-white justify-center justify-items-center items-center gap-5 relative z-10">
      <img src={Steinie} alt="Steinie" />
      <img src={Panhead} alt="Panhead" />
      <img src={Emersons} alt="Emersons" />
      <img src={WitherHills} alt="WitherHills" />
      <img src={TheNed} alt="TheNed" />
      <img src={DanielLeBrun} alt="DanielLeBrun" />
      <img src={BombaySapphire} alt="BombaySapphire" />
      <img src={Bacardi} alt="Bacardi" />
      <img src={Cuervo} alt="Cuervo" />
    </div>
  );
};

export default Logos;
